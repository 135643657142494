/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { Link, navigate } from 'gatsby';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';
import { SimulationResult } from '@/newcar/core/store/dataset/simulationResult';
import SimulationResultManagerStoreContext from '@/newcar/ui/common/context/SimulationResultManagerStoreContext';
import Layout from '@/common/ui/template/Layout';
import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import Footer from '@/newcar/ui/common/organism/Footer';
import NEWCAR_DEFINE from '@/newcar/util/define';
import Color from '@/newcar/ui/detail/molecule/Color';
import PowerSlideDoorBDf from '@/newcar/ui/detail/img/imgIconPowerSlideDoorBDf.svg';
import PowerSlideDoorLDf from '@/newcar/ui/detail/img/imgIconPowerSlideDoorLDf.svg';
import PowerSlideDoorLOption from '@/newcar/ui/detail/img/imgIconPowerSlideDoorLOption.svg';
import PowerSlideDoorLImpossible from '@/newcar/ui/detail/img/imgIconPowerSlideDoorLImpossible.svg';
import KeylessEntryDf from '@/newcar/ui/detail/img/imgIconKeylessEntryDf.svg';
import KeylessEntryOption from '@/newcar/ui/detail/img/imgIconKeylessEntryOption.svg';
import KeylessEntryImpossible from '@/newcar/ui/detail/img/imgIconKeylessEntryImpossible.svg';
import SmartKeyDf from '@/newcar/ui/detail/img/imgIconSmartKeyDf.svg';
import SmartKeyOption from '@/newcar/ui/detail/img/imgIconSmartKeyOption.svg';
import SmartKeyImpossible from '@/newcar/ui/detail/img/imgIconSmartKeyImpossible.svg';
import CruiseControlDf from '@/newcar/ui/detail/img/imgIconCruiseControlDf.svg';
import CruiseControlOption from '@/newcar/ui/detail/img/imgIconCruiseControlOption.svg';
import CruiseControlImpossible from '@/newcar/ui/detail/img/imgIconCruiseControlImpossible.svg';
import AutoBrakeDf from '@/newcar/ui/detail/img/imgIconAutoBrakeDf.svg';
import AutoBrakeOption from '@/newcar/ui/detail/img/imgIconAutoBrakeOption.svg';
import AutoBrakeImpossible from '@/newcar/ui/detail/img/imgIconAutoBrakeImpossible.svg';
import SupportBrakeDf from '@/newcar/ui/detail/img/imgIconSupportBrakeDf.svg';
import SupportBrakeOption from '@/newcar/ui/detail/img/imgIconSupportBrakeOption.svg';
import SupportBrakeImpossible from '@/newcar/ui/detail/img/imgIconSupportBrakeImpossible.svg';
import LaneAlertDf from '@/newcar/ui/detail/img/imgIconLaneAlertDf.svg';
import LaneAlertOption from '@/newcar/ui/detail/img/imgIconLaneAlertOption.svg';
import LaneAlertImpossible from '@/newcar/ui/detail/img/imgIconLaneAlertImpossible.svg';
import PulldownIcon from '@/newcar/ui/common/img/sprites/icon-pulldown.png';
import * as styles from '@/pages/simulation/index.module.styl';

const Page: FC = () => {
  const simulationResultManagerStore = useContext(SimulationResultManagerStoreContext);
  simulationResultManagerStore.restoreSimulationsLocalStorage();

  const [hasSimulationList, setHasSimulationList] = useState(false);
  const [simulationList, setSimulationList] = useState(simulationResultManagerStore.nowSimulationList);
  const [showAccordionList, setShowAccordionList] = useState(simulationList.map(() => false));
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    setHasSimulationList(simulationList.length > 0);
  }, [simulationList.length]);

  const showAccordion = (num: number) => {
    const list = showAccordionList.map((val, index) => (index === num ? !val : val));
    setShowAccordionList(list);
  };

  // detailページへのURL
  const getUrl = (value: SimulationResult): string => {
    let url = '';
    if (value && value.customerSelection && value.masterNumber) {
      let paramstring = '';
      const selection = value.customerSelection;
      if (selection) {
        let count = 1;
        paramstring += 't=';
        paramstring += (selection.term) ? selection.term : NEWCAR_CONST.TERM.ID.YEAR_11; // 期間
        if (selection.gradeId) { paramstring += `&g=${selection.gradeId}`; count += 1; } // グレードID
        if (selection.bodyColorId) { paramstring += `&b=${selection.bodyColorId}`; count += 1; } // ボディカラー
        if (selection.carNavi && selection.carNavi !== '100001') { paramstring += `&c=${selection.carNavi}`; count += 1; } // カーナビ
        if (selection.etc && selection.etc !== '100003') { paramstring += `&e=${selection.etc}`; count += 1; } // ETC
        if (selection.backCamera && selection.backCamera !== '100002') { paramstring += `&b2=${selection.backCamera}`; count += 1; } // バックカメラ
        if (selection.floorMat && selection.floorMat !== '100004') { paramstring += `&f=${selection.floorMat}`; count += 1; } // フロアマット
        if (selection.doorVisor && selection.doorVisor !== '100006') { paramstring += `&d=${selection.doorVisor}`; count += 1; } // ドアバイザー
        if (selection.dontHaveDoorVisor) { paramstring += `&d2=${1}`; count += 1; } // ドアバイザー無しの車両
        if (selection.wantNo && selection.wantNo !== '100005') { paramstring += `&w=${selection.wantNo}`; count += 1; } // 希望ナンバー
        if (selection.wantCar && selection.wantCar !== 'no') { paramstring += `&w2=${selection.wantCar}`; count += 1; } // もらえるオプション
        if (selection.maintenancePlan) { paramstring += `&m=${selection.maintenancePlan}`; count += 1; } // メンテナンスプラン
        if (value.masterNumber) { paramstring += `&m2=${value.masterNumber}`; count += 1; } // マスタ番号
        paramstring += `&c2=${count}`;
      }
      url = `${NEWCAR_DEFINE.PATH.DETAIL(value.customerSelection.id)}?${paramstring}`;
    }
    return url;
  };

  // 削除
  const deleteSimulation = (value: SimulationResult) => {
    simulationResultManagerStore.removeSimulation({
      customerSelection: value.customerSelection,
      masterNumber: value.masterNumber,
    });
    setSimulationList(simulationResultManagerStore.nowSimulationList);
  };

  // 審査へ進む
  const goToEntryForm = (value: SimulationResult) => {
    const simulation = value;
    // Vue版のシミュレーションの場合、car.idと同じ値をcar.id__normalizedに持たせる
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    if (simulation.car && simulation.car.id) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      simulation.car.id__normalized = simulation.car.id;
    }
    simulationResultManagerStore.saveEntryBreakdownFromSimulationResult(value);
    navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  };

  const powerSlideDoorImg = (param: string | undefined) => {
    switch (param) {
      case '両側標準':
        return <img src={PowerSlideDoorBDf} alt="feature" />;
      case '左側標準':
        return <img src={PowerSlideDoorLDf} alt="feature" />;
      case 'メーカー':
        return <img src={PowerSlideDoorLOption} alt="feature" />;
      case 'なし':
        return <img src={PowerSlideDoorLImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  const keylessEntryImg = (param: string | undefined) => {
    switch (param) {
      case '標準':
        return <img src={KeylessEntryDf} alt="feature" />;
      case 'メーカー':
        return <img src={KeylessEntryOption} alt="feature" />;
      case 'なし':
        return <img src={KeylessEntryImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  const smartKeyImg = (param: string | undefined) => {
    switch (param) {
      case '標準':
        return <img src={SmartKeyDf} alt="feature" />;
      case 'メーカー':
        return <img src={SmartKeyOption} alt="feature" />;
      case 'なし':
        return <img src={SmartKeyImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  const cruiseControlImg = (param: string | undefined) => {
    switch (param) {
      case '標準':
        return <img src={CruiseControlDf} alt="feature" />;
      case 'メーカー':
        return <img src={CruiseControlOption} alt="feature" />;
      case 'なし':
        return <img src={CruiseControlImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  const automaticBrakeImg = (param: string | undefined) => {
    switch (param) {
      case '標準':
        return <img src={AutoBrakeDf} alt="feature" />;
      case 'メーカー':
        return <img src={AutoBrakeOption} alt="feature" />;
      case 'なし':
        return <img src={AutoBrakeImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  const pedalSupportImg = (param: string | undefined) => {
    switch (param) {
      case '標準':
        return <img src={SupportBrakeDf} alt="feature" />;
      case 'メーカー':
        return <img src={SupportBrakeOption} alt="feature" />;
      case 'なし':
        return <img src={SupportBrakeImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  const laneDepartureAlertImg = (param: string | undefined) => {
    switch (param) {
      case '標準':
        return <img src={LaneAlertDf} alt="feature" />;
      case 'メーカー':
        return <img src={LaneAlertOption} alt="feature" />;
      case 'なし':
        return <img src={LaneAlertImpossible} alt="feature" />;
      default:
        return <></>;
    }
  };

  return (
    <Layout>
      <Header>
        <Breadcrumb
          items={[
            { to: NEWCAR_DEFINE.PATH.TOP, label: '新車TOP' },
            { label: 'シミュレーション結果' },
          ]}
        />
      </Header>

      <div className={`has-background-white-ter ${styles.backgroundPadding}`}>
        <div className="container">
          {!hasSimulationList ? (
            <div className="card has-text-centered is-padding-top-3 is-padding-bottom-3">
              保存されたシミュレーション結果はありません。
            </div>
          ) : simulationList.map((value, index) => (
            <div className="card is-padding-3 is-margin-bottom-3 is-relative" key={index.toString()}>
              <i className={`icon ${styles.iconTrash}`} onClick={() => deleteSimulation(value)} />
              <div className="columns is-mobile is-valign-center">
                <div className="column is-3-desktop is-4-touch">
                  <Link to={getUrl(value)}>
                    <figure className="image">
                      <img src={value.car?.thumbnailUrl} alt={value.car?.name} />
                    </figure>
                  </Link>
                </div>
                <div className="column is-9-desktop is-8-touch is-padding-left-4">
                  <div className="columns is-mobile">
                    <div className="column has-text-black-ter">
                      <p className="is-size-4-desktop is-size-6-touch has-text-link">{value.car && value.car.makerName}</p>
                      <p className="is-size-4-desktop is-size-5-touch has-text-link">{value.car && value.car.name}</p>
                      <p className="is-size-4 has-text-weight-bold">{value.grade && value.grade.name}</p>
                      <p>
                        <span className="is-size-4-desktop is-size-5-touch has-text-weight-semibold is-margin-right-1">
                          {CARMO_CONST.COMMA_FORMAT(value.totalPrice)}
                        </span>
                        <span className="is-size-5-desktop is-size-6-touch has-text-grey">月/円（税込）</span>
                      </p>
                    </div>
                    <div className="column is-hidden-touch is-padding-left-3 is-padding-top-7">
                      {/* PC用 審査ボタン */}
                      <div className="columns is-mobile is-full-height is-vcentered">
                        <Link to={getUrl(value)} className="column button is-large is-margin-right-2 has-text-weight-semibold is-flex">
                          内容を変更
                          <span className={`icon is-small icon-arrow top-link-icon-arrow has-background-primary ${styles.simulationresultArrow}`} />
                        </Link>
                        <button type="button" className="column button is-large is-link is-margin-left-2 has-text-weight-semibold" onClick={() => goToEntryForm(value)}>
                          審査・商談に進む
                          <span className={`icon is-small icon-arrow top-link-icon-arrow has-background-white ${styles.simulationresultArrow} ${styles.isWhite}`} />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* PC用 詳細 */}
                  <table className="table is-bordered is-hidden-touch is-margin-top-3 is-size-6 has-text-grey">
                    <tbody className="is-size-6">
                      <tr className={`${styles.simulationresultTableInfo}`}>
                        {/* 賃貸期間・駆動・定員・ミッション・排気量・エンジンタイプ */}
                        <td>
                          賃貸期間
                          {value.customerSelection?.term && NEWCAR_CONST.TERM.NAME[value.customerSelection.term]}
                        </td>
                        <td>{value.grade && value.grade.kudou}</td>
                        <td>
                          {value.grade && value.grade.capacity}
                          人
                        </td>
                        <td colSpan={2}>{value.grade && value.grade.mission}</td>
                        <td>
                          {value.grade && value.grade.displacement}
                          cc
                        </td>
                        <td>{(value.grade && value.grade.engine) ? value.grade.engine : 'ガソリン'}</td>
                      </tr>
                      <tr>
                        {/* 安全装備アイコン */}
                        <td colSpan={3}>
                          <ul className={`columns is-mobile ${styles.simulationresultIconUl}`}>
                            <li className="column is-flex has_verticel_centered">
                              {powerSlideDoorImg(value.grade?.powerSlideDoor)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              {keylessEntryImg(value.grade?.keylessEntry)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              {smartKeyImg(value.grade?.smartKey)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              {cruiseControlImg(value.grade?.cruiseControl)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              {automaticBrakeImg(value.grade?.automaticBrake)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              {pedalSupportImg(value.grade?.pedalSupport)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              {laneDepartureAlertImg(value.grade?.laneDepartureAlert)}
                            </li>
                            <li className="column is-flex has_verticel_centered">
                              <div className="is-flex has_verticel_centered">
                                <button type="button" className="button button_help is-primary" onClick={() => setShowHelp(!showHelp)}>
                                  <span>ヘルプ</span>
                                </button>
                              </div>
                            </li>
                          </ul>
                        </td>
                        {/* ボディカラー */}
                        <td className={`has-text-centered ${styles.simulationresultTd}`}>
                          カラー
                          <div>
                            {(!value.bodyColor || (value.bodyColor && !value.bodyColor.isSelected)) ? (
                              <>未指定</>
                            ) : (
                              <Color
                                bodyColors={value.bodyColor?.colorCode2 ? [`${value.bodyColor?.colorCode1}/${value.bodyColor?.colorCode2}`] : [`${value.bodyColor?.colorCode1}`]}
                                desktop
                                className="is-margin-auto"
                              />
                            )}
                          </div>
                        </td>
                        {/* オプション */}
                        <td colSpan={3}>
                          {value.optionstring || 'オプションなし'}
                          |
                          {value.maintenancePlan?.name || 'メンテプランなし'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* SP用 詳細 */}
              <table className="table is-bordered is-hidden-desktop is-margin-top-3 is-size-6 has-text-grey is-margin-bottom-0 is-full-width">
                <tbody className="is-size-6">
                  <tr className={`${styles.simulationresultTableInfo}`}>
                    {/* 賃貸期間・駆動・定員・ミッション・排気量・エンジンタイプ */}
                    <td>{value.customerSelection?.term && NEWCAR_CONST.TERM.NAME[value.customerSelection.term]}</td>
                    <td>{value.grade && value.grade.kudou}</td>
                    <td>
                      {value.grade && value.grade.capacity}
                      人
                    </td>
                    <td>{value.grade && value.grade.mission}</td>
                    <td>
                      {value.grade && value.grade.displacement}
                      cc
                    </td>
                    <td>{(value.grade && value.grade.engine) ? value.grade.engine : 'ガソリン'}</td>
                  </tr>
                </tbody>
              </table>

              <div className="is-full-width" onClick={() => showAccordion(index)}>
                <div className={`is-hidden-desktop is-size-5 has-text-link is-flex has-text-centered ${styles.simulationResultShowdetail}`}>
                  {!showAccordionList[index] ? '詳細を見る' : '詳細を閉じる'}
                  <img src={PulldownIcon} className={`${showAccordionList[index] && styles.reverse} is-margin-left-2`} alt="矢印" width="15" />
                </div>
              </div>

              <div className={`${styles.accordionBody} ${showAccordionList[index] && styles.isShow}`}>
                <table className={`table is-bordered is-hidden-desktop is-size-6 has-text-grey is-full-width ${styles.simulationResultDetail}`}>
                  <tbody className="is-size-6">
                    <tr>
                      {/* 安全装備アイコン */}
                      <td colSpan={6}>
                        <ul className={`column columns is-mobile ${styles.simulationresultIconUl}`}>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {powerSlideDoorImg(value.grade?.powerSlideDoor)}
                          </li>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {keylessEntryImg(value.grade?.keylessEntry)}
                          </li>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {smartKeyImg(value.grade?.smartKey)}
                          </li>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {cruiseControlImg(value.grade?.cruiseControl)}
                          </li>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {automaticBrakeImg(value.grade?.automaticBrake)}
                          </li>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {pedalSupportImg(value.grade?.pedalSupport)}
                          </li>
                          <li className="column is-flex has_verticel_centered is-narrow">
                            {laneDepartureAlertImg(value.grade?.laneDepartureAlert)}
                          </li>
                          <li className="column is-flex has_verticel_centered">
                            <div className="is-flex has_verticel_centered">
                              <button type="button" className="button button_help is-info" onClick={() => setShowHelp(!showHelp)}>
                                <span>ヘルプ</span>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      {/* ボディカラー */}
                      <td className={`has-text-centered ${styles.simulationResultSpWidth}`}>
                        カラー
                        <div className="is-margin-bottom-1">
                          {(!value.bodyColor || (value.bodyColor && !value.bodyColor.isSelected)) ? (
                            <>未指定</>
                          ) : (
                            <Color
                              bodyColors={value.bodyColor?.colorCode2 ? [`${value.bodyColor?.colorCode1}/${value.bodyColor?.colorCode2}`] : [`${value.bodyColor?.colorCode1}`]}
                              desktop
                              className="is-margin-auto"
                            />
                          )}
                        </div>

                      </td>
                      {/* オプション */}
                      <td colSpan={4}>
                        {value.optionstring || 'オプションなし'}
                        |
                        {value.maintenancePlan?.name || 'メンテプランなし'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* SP用 審査ボタン */}
              <div className="column is-hidden-desktop is-padding-top-4 is-padding-bottom-2">
                <div className="columns is-mobile is-full-height is-vcentered">
                  <Link to={getUrl(value)} className="column button is-large is-margin-right-2 has-text-weight-semibold is-flex">
                    内容を変更
                    <span className={`icon is-small icon-arrow top-link-icon-arrow has-background-primary ${styles.simulationresultArrow}`} />
                  </Link>
                  <button type="button" className="column button  is-large is-info is-margin-left-2 has-text-weight-semibold" onClick={() => goToEntryForm(value)}>
                    審査・商談に進む
                    <span className={`icon is-small icon-arrow top-link-icon-arrow has-background-white ${styles.simulationresultArrow} ${styles.isWhite}`} />
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className={`modal ${showHelp && 'is-active'}`}>
            <div className="modal-background" onClick={() => setShowHelp(!showHelp)} />
            <div className="modal-content has-background-white">
              <div className={`${styles.simulationresultPopup}`}>
                <p className="is-size-5 has-text-black-ter has-text-weight-semibold">アイコンについて</p>
                <div className="is-size-5 has-text-black-ter is-margin-top-3">
                  <div className={`columns is-multiline ${styles.simulationresultHelpSupportItems}`}>
                    <div className="column is-3-desktop is-flex">
                      <img src={PowerSlideDoorBDf} alt="feature" />
                      <p>パワースライドドア（両側）</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={PowerSlideDoorLDf} alt="feature" />
                      <p>パワースライドドア（左側）</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={KeylessEntryDf} alt="feature" />
                      <p>キーレスエントリー</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={SmartKeyDf} alt="feature" />
                      <p>スマートキー</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={CruiseControlDf} alt="feature" />
                      <p>クルーズコントロール</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={AutoBrakeDf} alt="feature" />
                      <p>自動ブレーキ</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={SupportBrakeDf} alt="feature" />
                      <p>ペダル踏み間違い時サポートブレーキ</p>
                    </div>
                    <div className="column is-3-desktop is-flex">
                      <img src={LaneAlertDf} alt="feature" />
                      <p>車線はみ出しアラート</p>
                    </div>
                  </div>
                </div>

                <p className="is-size-5 has-text-black-ter is-margin-top-5 is-margin-bottom-3"><strong>アイコンの色について</strong></p>
                <div className={`columns is-multiline is-size-5 has-text-black-ter ${styles.simulationresultHelpSupportItems}`}>
                  <div className="column is-3-desktop is-flex">
                    <img src={PowerSlideDoorLDf} alt="feature" />
                    <p>標準装備</p>
                  </div>
                  <div className="column is-3-desktop is-flex">
                    <img src={PowerSlideDoorLOption} alt="feature" />
                    <p>メーカーオプションで搭載可能</p>
                  </div>
                  <div className="column is-3-desktop is-flex">
                    <img src={PowerSlideDoorLImpossible} alt="feature" />
                    <p>非対応</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  );
};

export default Page;
