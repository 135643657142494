// extracted by mini-css-extract-plugin
export const backgroundPadding = "index-module--background-padding--2aITy";
export const iconTrash = "index-module--icon-trash--pkmML";
export const simulationresultArrow = "index-module--simulationresult-arrow--1ipiL";
export const isWhite = "index-module--is-white--1JIaH";
export const simulationresultTableInfo = "index-module--simulationresult-table-info--1bpa3";
export const simulationresultIconUl = "index-module--simulationresult-icon-ul--3V4nn";
export const simulationResultShowdetail = "index-module--simulation-result-showdetail--33_Lh";
export const simulationResultDetail = "index-module--simulation-result-detail--2fkEd";
export const accordionBody = "index-module--accordion-body--CKB0g";
export const isShow = "index-module--is-show--MjG19";
export const reverse = "index-module--reverse--1a3CM";
export const simulationresultTd = "index-module--simulationresult-td--3jfCo";
export const simulationResultSpWidth = "index-module--simulation-result-sp-width--3J5DI";
export const simulationresultPopup = "index-module--simulationresult-popup--191mR";
export const simulationresultHelpSupportItems = "index-module--simulationresultHelpSupportItems--5TjfL";